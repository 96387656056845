@import url('./styles/sass/styles.css');

.bg-home {
  background-image: url("./images/home/background-home-desktop.jpg");
  
 
}
.bg-destination {
  background-image: url("./images/destination/background-destination-desktop.jpg");
  

}
.bg-crew {
  background-image: url("./images/crew/background-crew-desktop.jpg");
  
}
.bg-tekno {
  background-image: url("./images/technology/background-technology-desktop.jpg");

 
 
}

@media screen and (max-width: 800px){
    .bg-destination{
      background-image: url("./images/destination/background-destination-mobile.jpg");
    }
    .bg-tekno{
      background-image: url("./images/technology/background-technology-mobile.jpg");
    }
}






#picture{

left: 230px;
top: 343px;

}
#row{
flex-wrap: nowrap;
}





.butonCircle{
    border: 1px solid rgb(91, 90, 90);
    width: 3rem;
    height: 3rem;
   margin-bottom: 1rem;
    border-radius: 50%;
    display:flex;
    justify-content: center;
    align-items: center;
    margin-right: 2rem;
    cursor: pointer;
}
.all-tekno{
    height: 100%;
}
.total-section-tekno{
    width: 100%;

    display: flex;
    height: 100%;
    display: flex;
   
    align-items: center;
}
.containerTekno{
 height: 100%;
    padding: 0;

    
    
}
.teknoText{
    height: 100%;
}
.imgtek{
    height: 100%;
    img{
        height: 100%;
    }
}

.section-vide{
    width: 10%;
}
.section-vide2{
    width: 5%;
}
.section-tekno{
    width: 90%;
 
    height: 100%;
    margin: auto;
}
.butonCircle:hover{
    border: 1px solid white;
}
.desc-tekno{
    width: 70%;
}
.bg{
    overflow: hidden;
  

}
.selectedTekno{
    color: black;
    background-color: white;
}

@media screen and (max-width: 800px){
    .containerAll .menu{
        display: flex !important;
        flex-direction: row !important;
        margin-top:1rem;
    }
    .desc-tekno{
        width: 100%;
    }
   .teknoText{
    margin-top: 1rem;
    text-align: center;


   }
   .section-vide{
 
    width: 0;
   }
   .containerTekno{
    flex-direction: column-reverse !important;

   }
   .texte-container h2{
    font-size: 2.1rem;
    color: rgb(195, 189, 189);
   }
   .texte-container h4{
    font-size: 1.5rem;
    margin: 0;
    color: gray;
   }
   .texte-container p {
    margin-top: 1rem;
    text-align: justify;
    
    }
}
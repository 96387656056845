.bouton{
    border-radius: 100%;
    height: 10px;
    width: 10px;
    background-color: #ffffff;
    opacity: .2;
    border: none;
    -webkit-transition: all .5s;
    transition: all .5s;
    cursor: pointer; 
  
  }

  .nav-crew{
height: 10%;
  }
  .section-crew{
   
    margin:auto ;
 
    width: 80%;
    height: 90%;  
  
  
  
  }

  .main-crew{
    width: 100%;
   height: 80%;


    display: flex;
    justify-content: center;
    align-items: end;
    height: 90%;
    
  }
  .title-crew{
    height: 10%;
  }
  .crewimg{
    height: 100%;

    display: flex;
    justify-content: center;
    img{
     height: 100%;
 
    }
  }
  .containerCrew{

 height: 100% !important;
  }
  #h2Crew{
    font-size: 4rem;
  }
  .bouton:hover {
   
    opacity: .5; }


      .boutonDiv{
        margin: 90px 0 61px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 15px; 

      }
.selected {
  background-color: white;
  opacity: 1;
}
.texte-container{
  h4{

    margin-top: 1rem;
  }
 
    margin-top: 2rem;

}



#containerAll img{
  margin-bottom: 0;
  max-width: 100%;
  justify-self: end;
 



}



@media screen and (min-width:801px){
 
   
  }


@media screen and (max-width: 800px){
  .containerCrew{
    flex-direction: column-reverse !important;
  }
  .boutonDiv{
    margin: 1rem 0 1rem 0;
    
  }
  .responsive-content-text{
    margin-top: 1rem !important;
  }
  #containerAll{
 
    border-bottom: solid 1px#d0d6f9;
 margin-bottom: 0;


     height: 20rem;
  }
  #containerAll img{
    margin-bottom: 0;
    max-width: 100%;
 


  }
}



    .explore {
        visibility: visible;
        width: 274px;
        height: 274px;
        left: 1001px;
        top: 495px;
        background-color: azure;
        box-shadow: 0 0 0 0 rgba(52, 172, 224, 1);
        animation: pulse-blue 2s infinite;
     
      }
    
      @keyframes pulse-blue {
        0% {
    
          box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
        }
        
        70% {
          
          box-shadow: 0 0 0 100px rgba(52, 172, 224, 0);
        }
        
        100% {
         
          box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
        }
      }
      #explore {
        color: black;
      }
      .texte-home{

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 2rem;
      

      }
      .containerHome{
        display: flex;
        justify-content: space-between;
       align-content: center;
       align-items: center;
        
        padding-bottom: 10rem;
       
      }
      .explore-container{
        width: 50%;
      }
      .containerAll{
        width: 50%;
        max-height: 100%;
     }
   #home{
   margin-left: 5rem;
   }
#expore{
    display: flex;
 align-self: flex-end;
 justify-content: center;
 
   }
   @media screen and (max-width: 800px){
    .containerHome{
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
    #home{
      margin-left: 0;
    }
  
  
    .texte-home{
      width: 100%;
      margin-right: 2rem;
      margin-bottom: 1rem;

    }
    .textHome{
      margin: 0 1rem 2rem 1rem;
    }
    .containerAll{
      width: 100%;
    }
    .texte-home div h1{
      font-size: 7rem;
      text-align: center;
    }
    .titreHome{
      margin: auto;
    }
   

   }

#navbarHome {
    /* position: absolute; */
    width: 100%;
    height: 96px;
    display: flex;
   flex-wrap: nowrap;
   
    margin-bottom: 3rem;
  
  

  }
  .headerNav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    
    margin-bottom: 3rem;
  }
  .logo{
 position: absolute;
 left: 2rem;
  }

  .text-navbar div {
    padding: 10px;
    margin: 10px;
    display: flex;
    justify-content: flex-end;
  }
  .text-navbar div p {
    text-decoration: none;
    color: white;
    margin-bottom: 0;
  }

  
  .containerNav{
    width: 80%;
  }

  .navbar__line {
    z-index: 1;
    height: 1px;
     /*    

     width: 100%;
     */
    background: #ffffff;
    mix-blend-mode: normal;
    opacity: 0.25;
    margin: 0 -15px 0 10px;
  }
  .navPrincipal{
    height: 100%;
  
    padding: 3rem;
  }
  .navPrincipal:hover{

border-bottom: #6b6363 solid 2px;
  }
  .navPrincipal-active{
    border-bottom: #ffffff solid 2px;
  }

  
  .active {
   
    color: #fff;
  }
  @media screen and (min-width: 801px){
   
    #menuContent{
  background-color: transparent;
  backdrop-filter: blur(10px);
  }

}
@media screen and (max-width: 800px){
 .headerNav{
  display: block;}
 .logo{
  
 margin-top: 0.6rem;
 }
 .logo img{
  width: 2rem;
 }
  #menuContent{
 display: none !important;
   
   transition: right .5s ease; 
   flex-direction: column;
   position:absolute;

   background-color: transparent;
   backdrop-filter: blur(10px);
 
  /*
  
  border: 1px solid red;
  */  
   height: 100vh;
 justify-content:center !important;
 z-index: 99;
    
  }
  #menuContent.showMenu{
    display: flex !important;
 right: 0;
   
  
  }

    #burger1::before,
    #burger1::after {
    content:'';
    width:20px;
    /* margin-top: 1rem; */
    height:3px;
    background:white;
    display:inline-block;
    position:absolute;
    transform-origin: center;
    transition: all 0.3s ease;
  }
  #burger1::before {
    top:-7px;
    /* margin-bottom: 2rem;/ */
    margin-top: .1rem;
  }
  #burger1::after {
    top:7px;
  }
  #burger1.opene {
    background:transparent;
  }
  
  #burger1.opene::before {
    transform: rotate(45deg); 
    top:0;
  }
  #burger1.opene::after {
    transform: rotate(-45deg);
    top:0;
  }

  #link{
    display: flex;
    color: white;
    padding: 1rem;
  
  position: absolute;
  right: 0;

    margin-top: 1.2rem;
    margin-right:1rem ;
    z-index: 100;
    position: absolute;
    /*
    
    border: solid 1px greenyellow;
    */ 
   
  }
  #burger1 {
    width:20px;
    margin-bottom: 1rem;
    height:3px;
    background:white;
    display:inline-block;
    position:relative;
    
  }
  .logo{
    position: absolute;
    top: 1rem;
  
  }
   .navPrincipal{
  border: none;
  height: auto;
   
  }
  .navbar__line{
    display: none;
  }
 

  }

  

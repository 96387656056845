.descriptionP{
    border-bottom: solid 1px white;
}

.distanceTravel {
    margin-top: 1rem;
}
.distanceTravel div P{
     margin-bottom: 0;
 }

 .destination div div {
    margin-left: 2rem;
  }
  .tex_destination{
    margin-top: 3rem;
    width: 80%;
  }
  
  .destination {
    width: 100%;
  }
  .navbar h6{
    cursor: pointer;
  }
  .destination__select {
    display: inline-block;
    &::after {
      content: "";
      margin-top: 12px;
      display: block;
      width: 0;
      height: 3px;
      background: rgba(255, 255, 255, 0.4);
      -webkit-transition: width 0.3s;
      transition: width 0.3s;
    }
    &:hover::after {
      width: 100%;
    }
  }
  
  .nav-text--active {
    border-color: #ffffff;
    border-bottom: solid;
    padding-bottom: 12px;
  }

  @media screen and (max-width: 800px){
  #navDestination{
      
      display: flex;
      flex-wrap: nowrap;

    }

    .distanceTravel{
      width: 100%;
      margin: 0;
      display: flex;
      flex-direction: column;
    }

    .centre{
     
     text-align: center;

    }
    .tex_destination{
      width: 100%;
      text-align: center;
    }
    #travel,#distance{
      font-size: 28px ;
    }

  

  }
 
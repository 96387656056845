.bg-home,.bg-destination,.bg-crew,.bg-tekno{
  
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    position: relative;
   
}
@media screen and (min-width: 801px) {

    .bg-home,.bg-destination,.bg-crew,.bg-tekno{
   height: 100vh;
}
.container{
    max-height: 75%;
    justify-content: center;
    align-items: center;
   
    }
   

   
 }
